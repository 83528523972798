<template>
    <div>
        <div class="head-m">
            <div class="head-logo-m">
                <img class="imIcom" :src="imgObj.homeImg" width="50px" height="50px">
                <div class="head-logo-name-m">
                    <div class="appName-m">旺街邮</div>
                    <div class="appData-m">发货找车的物流货运平台</div>
                </div>
                <div class="zhojin"></div>
                <el-dropdown class="edr" trigger="click" @command="handleCommand">
                      <span class="el-dropdown-link">
                         <img class="navigation-m" :src="imgObj.navigation">
                      </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="dynamic">最新动态</el-dropdown-item>
                        <el-dropdown-item command="about">关于我们</el-dropdown-item>
                        <el-dropdown-item command="shipments">老板发货</el-dropdown-item>
                        <el-dropdown-item command="order">司机接单</el-dropdown-item>
                        <el-dropdown-item command="relation">联系我们</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <div class="head-img-m img_com"></div>
        <div id="dynamic" class="headline-m">
           <div class="headline-m-item" >
            <div>最新动态</div>
            <img :src="imgObj.comma_m">
           </div>
        </div>
        <div class="marginTop-10">
            <div class="buttonCursor_m" v-for="item in dataList " :key="item.id"
                 @click="onDynamicClick(item.id)">

                <div class="flex-column-time-m">
                    <div class="textName-m fontBold">{{item.title}}</div>
                    <div class="text5-666">{{item.createTime}}</div>

                </div>
                <img class="stable_img" :src="item.image">
            </div>
        </div>
        <div  id="about" class="headline-m">
            <div class="headline-m-item" >
                <div>关于我们</div>
                <img :src="imgObj.comma_m">
            </div>
        </div>
        <div class="aboutUsCom-m">
            &emsp;&emsp;旺街邮是一家互联网物流服务平台。通过共享模式整合运力资源，完成海量运力储备，依托移动互联、大数据和人工智能技术，搭建“便捷、平价、安全、专业”的货运平台，实现全车型的即时智能调度，为个人、商户及企业提供高效的物流解决方案。
        </div>
        <div class="directionAlign-items marginTop-30 ">
            <div>
                <div class="directionRow alignItems ">
                    <div class="text20">3000</div>
                    <div class="text15">+</div>
                </div>
                <div class="text16_66 marginTop-15">服务企业用户</div>
                <div class="lin18 marginTop-15"></div>
            </div>
            <div class="marginLeft-60 ">
                <div class="directionRow alignItems ">
                    <div class="text20">20000</div>
                    <div class="text15">+</div>
                </div>
                <div class="text16_66 marginTop-15">助力货车司机</div>
                <div class="lin18 marginTop-15"></div>
            </div>
        </div>
        <div class="about-us-to  marginTop-30"></div>
        <div class="orderView-m ">
            <div id="shipments" class="headline-m padding-top-10">
                <div class="headline-m-item " >
                    <div>老板发货找车</div>
                    <img :src="imgObj.comma_m">
                </div>
            </div>
            <div class="directionJustify-content   ">
                <div>
                    <div class="directionRow alignItems ">
                        <div class="text20">发货更省钱</div>
                    </div>
                    <div class="text10_66 marginTop-15">智能建议价&emsp;自由喊价</div>
                    <div class="lin18 marginTop-15"></div>
                </div>
                <div class="marginLeft-20 ">
                    <div class="directionRow alignItems ">
                        <div class="text20">直达不中转</div>
                    </div>
                    <div class="text10_66 marginTop-15">在线下单&emsp;省时省力</div>
                    <div class="lin18 marginTop-15"></div>
                </div>
            </div>
            <div  id="order"  class="headline-m marginTop-290">
                <div class="headline-m-item" >
                    <div>司机接单拉货</div>
                    <img :src="imgObj.comma_m">
                </div>
            </div>
            <div class="directionJustify-content ">
                <div>
                    <div class="directionRow alignItems ">
                        <div class="text20">货源多/结单快</div>
                    </div>
                    <div class="text10_66 marginTop-15">平台每天上万订单</div>
                    <div class="lin18 marginTop-15"></div>
                </div>
                <div class="marginLeft-20 ">
                    <div class="directionRow alignItems ">
                        <div class="text20">无需路边趴活</div>
                    </div>
                    <div class="text10_66 marginTop-15">在线接单不用等</div>
                    <div class="lin18 marginTop-15"></div>
                </div>
            </div>

        </div>
        <div id="relation" class="headline-m">
            <div class="headline-m-item" >
                <div>联系我们</div>
                <img :src="imgObj.comma_m">
            </div>
        </div>
        <div  class="contactUs-m">
            <div class="contact-us-view-m">

                <div class="text12_22">杭州旺街网络科技有限公司</div>
                <div class="flex-column-m">
                    <div class="addressPhone marginTop-10" >
                        <img :src="imgObj.address_icon">
                        <div class="text12-55">杭州市萧山区农业大厦1号楼25层</div>
                    </div>
                    <div class="addressPhone marginTop-10">
                        <img :src="imgObj.phone_icon">
                        <div class="text12-55">0571-82136687</div>
                    </div>
                </div>


            </div>

        </div>
        <div>
            <div class="buttonLin_m"></div>
            <div class="button_m">
                <div class="flex-column_m">
                    <img class="logoImg_m" :src="imgObj.logoMin">
                    <div class="textName">杭州旺街网络科技有限公司</div>
                    <div class="text799">Copyright © 2018 杭州旺街网络科技 版权所有 </div>
                    <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备18030766号-1</a>
                    <div class="ganAn ">
                        <img class="badge_m" :src="imgObj.badge">
                        <div class="text799">
                            <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010902002583"
                               target="_blank">浙公网安备 33010902002583号</a></div>
                    </div>
                </div>
                <div class="buttonLin-height-m"></div>
                <div class="flex-column-m ">
                    <div class="text14c33m  marginTop-10">电话：0571-82136687</div>
                    <div class="text14c33m  marginTop-10">客服微信：wangjiekefu88</div>
                    <div class="text14c33m  marginTop-10">邮箱：wonstreet@126.com</div>
                    <div class="text14c33m marginTop-10">地址：杭州市萧山区农业大厦1号楼25层</div>
                </div>
                <div class="buttonLin-height-m"></div>
                <div class="button_m_code marginTop-10">
                    <div>
                        <img class="codeSize_m" :src="imgObj.downloadCode">
                        <div class="text5c33m marginTop-10">扫描下载旺街邮APP</div>
                    </div>
                    <div class="kanGe"></div>
                    <div>
                        <img class="codeSize_m" :src="imgObj.publicCode">
                        <div class="text5c33m marginTop-10">扫描关注旺街邮公众号</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import homeImg from '@/assets/img/logo-m.png'
    import navigation from '@/views/img/navigation.png'
    import badge from "@/views/img/badge.png";
    import publicCode from "@/views/img/public_code.png";

    import downloadCode from "@/views/img/download_code.png";
    import logoMin from "@/views/img/logo-min.png";
    import axios from "axios";
    import comma_m from '@/views/img/comma_m.png'
    import address_icon from '@/views/img/address-icon.png'
    import phone_icon from '@/views/img/phone-icon.png'
    export default {
        data() {
            return {
                slide: 0,
                sliding: null,
                pageNumber: 1,
                dataList: [],
                existNextPage: 0,
                imgObj: {homeImg, logoMin, navigation, badge,
                    publicCode, downloadCode,comma_m,
                    address_icon, phone_icon},
            }
        },
        methods: {
            onSlideStart() {
                this.sliding = true
            },
            onSlideEnd() {
                this.sliding = false
            },
            onDynamicClick: function (id) {
                // this.$router.push({
                //     path: '/news', query: {
                //         id: id,
                //     }
                // })
                let detailId=id+".html"
                let routeUrl = this.$router.resolve({
                    name:"News",
                    params:{id:detailId}
                });
                window.open(routeUrl .href, '_blank');
            },
            init() {
                this.HTTP = axios.create({
                    baseURL: 'https://yourestpro.wonstreet.com',
                    timeout: 1000,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    params: {
                        pageNumber: this.pageNumber,
                        pageSize: 4,
                    }
                });


                this.HTTP.post('/br/information/listWithPage')
                    .then((res) => {
                        this.dataList = res.data.result.list
                        this.existNextPage = res.data.result.existNextPage

                    })

            },
            handleCommand(command) {
                // this.$message('click on item ' + command);
                document.getElementById(command).scrollIntoView();
            },

        },
        created() {
            this.init()

        },
    }
</script>
<style>
    .head-m {
        display: flex;
        flex-direction: row;
        padding: 10px;
    }

    .head-logo-m {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex: 1;
        justify-items: center;
        align-items: center;

    }

    .head-logo-name-m {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center
    }

    .appName-m {
        font-size: 24px;
        margin-left: 20px;
        color: #333333;
    }

    .appData-m {
        font-size: 18px;
        color: #666666;
        margin-left: 20px;
    }

    .head-img-m {
        height: 156px;
        background: url('../img/zhichou-banner-m.png');

    }
    .padding-top-10{
        padding-top: 10px;
    }

    .headline-m {
      height: 56px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    .headline-m-item{
        display: flex;
        flex-direction: row;
    }

    .headline-m-item img{
        width: 12px;
        height: 12px;
        margin-left: 5px;
    }
    .headline-m-item div{
        font-size: 18px;
        color: #333333;
        font-weight: bold;
    }
    .img_com {
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: relative;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .aboutUsCom-m {
        font-size: 12px;
        color: #666666;
        margin-left: 10px;
        margin-right: 10px;
        line-height: 1.8;
    }

    .bossDelivery-m {


    }
    .orderView-m {
        height: 876px;
        background: url('../img/order_bg_m.png') no-repeat ;
        background-size: 100% 100%;
        position: relative;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .driverOrders-m {

    }

    .contactUs-m {
        height: 166px;
        background: url('../img/contact_us_m.png') no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-size: 100% 100%;
        position: relative;
        bottom: 0;
        left: 0;
        right: 0;

    }

    .navigation-m {
        width: 20px;
        height: 16px;
        margin-left: auto;

    }
    .el-dropdown{
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .buttonLin_m {
        width: auto;
        height: 4px;
        background-color: #00c395;
        margin-top: 20px;
    }

    .buttonLin-height-m {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 20px;
        height: 1px;
        background-color: #e8e8e8;
    }

    .button_m {
        padding-top: 10px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .flex-column_m {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .codeSize_m {
        width: 110px;
        height: 110px;
    }

    .button_m_code {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-bottom: 20px;
    }

    .text14c33m {
        font-size: 16px;
        color: #333333;

    }

    .text5c33m {
        font-size: 14px;
        color: #333333;

    }

    .flex-column-m {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .marginTop-10 {
        margin-top: 10px;
    }
    .marginTop-15 {
        margin-top: 15px;
    }

    .logoImg_m {
        width: 50px;
        height: 50px;
    }

    .textName {
        font-size: 16px;
        color: #333333;
        margin-top: 10px;
    }

    .text799 {
        font-size: 15px;
        color: #999999;
        margin-top: 10px;
    }

    .text5-666 {
        font-size: 14px;
        color: #666666;

    }  .text12-55 {
        font-size: 12px;
        color: #555555;

    }

    .ganAn {
        display: flex;
        flex-direction: row;

    }

    .badge_m {
        margin-top: 10px;
        height: 15px;
        width: 15px;
    }

    .kanGe {
        width: 20px;
    }


    .buttonCursor_m {
        display: flex;
        flex-direction: row;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
    }

    .buttonCursor_m img {
        width: 140px;
        height: 80px;
    }

    .flex-column-m {
        display: flex;
        flex-direction: column;
        margin-right: 5px;
    }

    .flex-column-time {
        display: flex;
        flex-direction: column;
    }

    .el-dropdown-link {
        cursor: pointer;
        color: #409EFF;
    }
    .zhojin{
        flex: 1;
    }
    .edr{
        margin-left: 50px;
        display: flex;
        flex-direction: column;
    }
    .flex-column-time-m{
        padding-right: 10px;
        display: flex;
        flex-direction: column;
    }
    .textName-m{
        flex: 1;
        flex-direction: column;
        font-size: 18px;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .fontBold{
        font-weight:bold
    }

    .directionRow {
        display: flex;
        flex-direction: row;

    }
    .directionAlign-items{
        display: flex;
        flex-direction: row;
        justify-content: center;

    }
    .directionJustify-content{

        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
    .alignItems {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
    }
    .text20 {
        color: #00c395;
        font-size: 20px;
        font-weight: bold;
    }
    .text15 {
        color: #00c395;
        font-size: 15px;
        font-weight: bold;
    }
    .marginLeft-60{
        margin-left: 60px;
    }
    .marginLeft-20{
        margin-left: 20px;
    }
    .marginTop-30{
        margin-top: 30px;
    }
    .marginTop-290{
        margin-top:290px;
    }
    .text16_66{
        font-size: 16px;
        color: #666666;
    }
    .text10_66{
        font-size: 10px;
        color: #666666;
    }
    .text12_22{
        font-size: 12px;
        color: #222222;
    }
    .about-us-to{
        height: 230px;
        background: url('../img/about_us_m.png') no-repeat;
        width: auto;

        background-size: 100% 100%;
        position: relative;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .contact-us-view-m {
        margin: 35px 20px;
        flex: 1;
        background-color: #ffffff;
        box-shadow: 0px 12px 40px 0px rgba(229, 229, 229, 0.42);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }
    .addressPhone{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .addressPhone img{
        width: 15px;
        height: 15px;
    }
</style>
